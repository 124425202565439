.tpl-btn {
  text-align: center;
  padding: 20px 0;
  color: rgb(11, 178, 221);
  border-style: solid;
  border-color: rgba(11, 178, 221, 0.3);
  line-height: 50px;
  border-radius: 5px;
  border-width: 1px;
}

.tpl-btn a {
  text-decoration: none;
  color: rgb(11, 178, 221);
  font-size: 18px;
}

.step-one {
  line-height: 30px;
  min-height: 112px;
  padding-top: 40px;
}

.uploadedFile-title{
  margin-bottom: 16px;
}

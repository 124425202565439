.error-wrapper {
  width: 200px;
  margin: 12px auto;
}

.error-msg {
  margin: 0 0 12px;
}

.error-button-wrapper {
  text-align: center;
}

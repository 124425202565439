.popup-custom-card {
  border-top: 1px solid rgb(0, 188, 212,1);
  border-bottom: 1px solid rgb(0, 188, 212,1);
  background-color: rgb(245, 245, 245);
  padding: 24px 12px;
  position: relative;
  margin-top: 15px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3);
}

.popup-custom-card .card-lable {
}
.popup-custom-card .card-lable span{
  color: rgb(187, 187, 187);
  font-size: 12px;
}

.popup-custom-card .card-lable .error{
  color: rgb(255, 64, 129) !important;
}


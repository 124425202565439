.main-container {
  float: left; }

.dialog {
  padding-top: 50px; }

.item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin: 4px 0;
  transition: background-color 0.3s ease;
  cursor: move; }
  .item .reorder-icon-container {
    margin-right: 8px;
    padding-top: 2px; }
  .item .structure-name {
    width: calc(100% - 28px); }

.buttons-container {
  padding-top: 15px;
  float: right; }
  .buttons-container .submit {
    margin-left: 10px; }

.react-flow__node.selected {
  box-shadow: 1px 3px 10px rgb(0, 188, 212);
}

.controls {
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  background-color:whitesmoke;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Dropzone {
    float: none;
}

.deleteFile {
    color:red !important;
    cursor: pointer;
}

.uploadedFile {
    margin-left: 12px;
}

.fileError {
    color:red !important;
}
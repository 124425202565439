.otc-download-button {
  float: left;
  padding: 0 7px;
}

.otc-download-button-disabled {
  float: left;
  padding: 0 7px;
  pointer-events: none;
  cursor: default;
}

.otc-download-button-disabled svg {
  fill: rgba(0, 0, 0, 0.3) !important;
}

.otc-download-button:hover {
  background-color: rgba(153, 153, 153, 0.2);
}

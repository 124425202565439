.ratio-wp{
  background-color: rgb(39, 40, 34);
}
.ratio-wp > * {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.ratio-editor{}
.ratio-viewer{}

.add-underlying {
  width: auto;
	display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 15px;
}

.add-underlying .btn {
  border: none;
  position: relative;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  margin: auto;
  padding: 19px 22px;
  transition: all .2s ease;
  outline: none;
  background: none;
}

.add-underlying .btn:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 16px;
  display: block;
  border-radius: 28px;
  background: rgba(0, 188, 212,.5);
  width: 36px;
  height: 36px;
  transition: all .3s ease;
}

.add-underlying .btn span {
  position: relative;
  font-size: 14px;
  vertical-align: middle;
}

.add-underlying .btn svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  margin-right: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  stroke-width: 2;
}

.add-underlying .btn.enable:hover:before {
  width: 100%;
  background: rgba(0, 188, 212,1);
  color: #fff;
}

.add-underlying .btn.enable:hover span {
  color: #fff;
}

.add-underlying .btn:active {
  transform: scale(.96)
}

/* .markups-wrapper .markup-item:nth-child(even) {
  background-color: rgb(245, 245, 245);
}
.markups-wrapper .markup-item:first-child {
  border-top: 1px solid rgb(0, 188, 212,1);
} */
.markups-wrapper .markup-item {
  /* border-bottom: 1px solid rgb(224, 224, 224); */
  /* border-bottom: 1px solid rgb(0, 188, 212,1);
  border-left: 1px solid rgb(0, 188, 212,1);
  border-right: 1px solid rgb(0, 188, 212,1); */
  border-top: 1px solid rgb(0, 188, 212);
  border-bottom: 1px solid rgb(0, 188, 212);
  background-color: rgb(245, 245, 245);
  padding: 24px 12px;
  position: relative;
  margin-top: 15px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3);
}

.group-item {
  margin-top: 8px;
  padding: 0 35px 37px;
  border-bottom: 1px solid rgba(0, 188, 212, 0.5);
}

.group-item:last-child {
  border-bottom: none;
}

.group-item:first-child {
  margin-top: 0;
}

.close-group {
  color: rgb(0, 188, 212)!important;
  cursor: pointer;
  position: absolute;
  right: 35px;
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  border: 2px solid rgb(0, 188, 212);
}

.close-group:hover {
    color: rgb(255, 255, 255)!important;
    background-color: rgb(0, 188, 212);;
}

.close-marckup {
  color: rgb(255, 64, 129) !important;
  cursor: pointer;
  top: 8px;
  right: 16px;
  position: absolute;
  border-radius: 15px 15px;
  width: 18px !important;
  height: 18px !important;
}
.close-marckup:hover{
  color: rgb(255, 255, 255) !important;
  background-color: rgb(255, 64, 129);
}

.markups-wrapper .markup-item .markup-box div {
  width: 33.3% !important;
}

.Dropzone {
    float: none;
}

.deleteFile {
    color:red !important;
    cursor: pointer;
}

.uploadedFile {
    margin-left: 12px;
}

.fileError {
    color:red !important;
}

.Dropzone-center {
  text-align: center;
  border-style: dashed;
  border-color: rgb(11, 178, 221);
  line-height: 150px;
  border-radius: 5px;
  border-width: 2px;
}
